import React from 'react';
import Home from './Home';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

// import Contact from './Contact';
import Category from './Category';
import Search from './Search';
import Detail from './Detail';

import '../css/style.css';

const App = () =>{
  return(
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/search/:search" component={Search}/>
        <Route exact path="/category/:categoryId" component={Category}/>
        <Route exact path="/:newsId" component={Detail}/>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
