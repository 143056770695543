import React,{useEffect} from 'react'
import { useHistory, NavLink, useParams } from "react-router-dom";
import { useState } from 'react';

const Header =()=>{
    const locale = 'en';
    const {categoryId} = useParams()
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
    const year = new Date().getFullYear();
    const date = `${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${year} \n\n`;
    const [csss, setSccc] = useState(false)
    const [search, setSearch] = useState();
    const [searchHead, SetSHead] = useState('template-search');
    const inputData=(event)=>{
        setSearch(event.target.value);
      }
      const history = useHistory();

      const onSubmit = (e) => {
        e.preventDefault()
        let path = `/search/` +search;
            SetSHead('template-search')
        history.push(path);
        // console.log(conFom)
      }
      useEffect(() => {

        setSccc(false)
      }, [categoryId])
    return (
        <>
        <header className="rt-header sticky-on">
            <div id="sticky-placeholder"></div>
            <div className="topbar topbar-style-1" id="topbar-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="meta-wrap">
                                <span className="rt-meta">
                                    <i className="far fa-calendar-alt icon"></i>
                                    <span className="currentDate">
                                        {date}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="rt-topbar-right">
                            <div className="social-wrap d-none d-xl-block">
                                <ul className="rt-top-social">
                                    <li className="follow">Follow Us:</li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                        <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                        <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    <li><a href="https://www.pinterest.com/" target="_blank">
                                        <i className="fab fa-pinterest-p"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.whatsapp.com/" target="_blank">
                                        <i className="fab fa-skype"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-main header-main-style-1 navbar-wrap" id="navbar-wrap">
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="site-branding">
                        <NavLink className="dark-logo" to="/">
                            <img width="162" height="52" src="/assets/Images/logo-ianslive.png" alt="neeon" />
                        </NavLink>
                        <NavLink className="light-logo" to="/">
                            <img width="162" height="52" src="/assets/Images/logo-ianslive.png" alt="neeon" />
                        </NavLink>
                        </div>
                        <div className="main-menu">
                        <nav className="main-menu__nav">
                            <ul>
                                <li className=" active">
                                    <NavLink className="animation" to="/">होम</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/national">राष्ट्रीय</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/sports">खेल</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/international">अंतरराष्ट्रीय </NavLink>
                                </li>

                                <li className="">
                                    <NavLink className="animation" to="/category/business">व्यापार</NavLink>
                                </li>
                                
                                <li className="">
                                    <NavLink className="animation" to="/category/entertainment">मनोरंजन</NavLink>
                                </li>

                                <li className="">
                                    <NavLink className="animation" to="/category/science-tech">विज्ञान और प्रौद्योगिकी</NavLink>
                                </li>
                            </ul>
                        </nav>
                        </div>

                        <ul className="header-action-items">
                            <li className="item" onClick={()=>{
                                            SetSHead('template-search open')}}>
                                <NavLink to="#"><i className="fas fa-search"></i></NavLink>
                            </li>
                        
                        </ul>
                        <div id="template-search" className={searchHead}>
                            <button type="button" className="close" onClick={()=>{
                                            SetSHead('template-search')}}>×</button>
                            <form className="search-form" onSubmit={onSubmit}>
                                
                                <input type="search" onChange={inputData} name="search" value={search} placeholder="Search" />
                                <button type="submit" className="search-btn btn-ghost style-1">
                                <i className="flaticon-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </header>
        <div className="rt-mobile-header mobile-sticky-on">
            <div id="mobile-sticky-placeholder"></div>
            <div className="mobile-menu-bar-wrap" id="mobile-menu-bar-wrap">
                <div className="mobile-menu-bar">
                    <div className="logo">
                    <NavLink to="/">
                        <img src="/assets/Images/logo-ianslive.png" alt="neeon" width="162" height="52" />
                    </NavLink>
                    </div>
                    <span className="sidebarBtn" onClick={()=>{setSccc(!csss)}}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    </span>
                </div>
                <div className="rt-slide-nav" style={{display: !csss ? 'none':'block'}}>
                    <div className="offscreen-navigation">
                        <nav className="menu-main-primary-container">
                            <ul className="menu">
                            <li className=" active">
                                    <NavLink className="animation" to="/">Home</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/national">National</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/sports">Sports</NavLink>
                                    
                                </li>
                                <li className="">
                                    <NavLink className="animation" to="/category/international">International </NavLink>
                                </li>

                                <li className="">
                                    <NavLink className="animation" to="/category/business">Business</NavLink>
                                </li>
                                
                                <li className="">
                                    <NavLink className="animation" to="/category/entertainment">Entertainment</NavLink>
                                </li>

                                <li className="">
                                    <NavLink className="animation" to="/category/science-tech">Science &  Technology</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Header;