import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';

const Detail = () =>{
const {newsId} = useParams();
const [collectionData, setCollectionData] = useState([]);
const [latData, setLatestData] = useState({})
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/'+item.slug,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const getData = async() =>{
   const apiD= `https://cms.iansnews.in/api/news/portals/${newsId}`
   axios.get(apiD)
.then(res =>
  {

   let main_content_string = res.data.results[0].content;
   let iansIndex = main_content_string.indexOf("--आईएएनएस") ? main_content_string.indexOf("--आईएएनएस") : main_content_string;
   let new_main_content_string = main_content_string.substring(0, iansIndex);
 
   setCollectionData({
   byline: res.data.results[0].byline,
   content: res.data.results[0].short_desc + new_main_content_string,
   id: res.data.results[0].id,
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
   console.log("Your new array of modified objects here")
})
.catch(err => { console.log('Google api calendar error', err) })
}
 useEffect(() => {
   LatestData();
   getData();
}, [newsId])

    return(
        <>
   <div id="wrapper" className="wrapper">
      <div id="main_content" className="footer-fixed">
      <Header />
         <main>
            <section className="rt-sidebar-section-layout-2">
               <div className="container">
                  <div className="row gutter-40 sticky-coloum-wrap">
                     <div className="col-xl-9 sticky-coloum-item">
                        <div className="rt-left-sidebar-sapcer-5">
                           <div className="rt-main-post-single grid-meta">
                              <div className="post-header">
                                 <h2 className="title">
                                 {collectionData.title}
                                 </h2>
                                 <div className="post-meta">
                                    <ul>
                                       <li>
                                          <span className="rt-meta">
                                             by <NavLink to="#" className="name">{collectionData.byline}</NavLink>
                                          </span>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <figure className="post-img">
                                 <img src={collectionData.image} alt={collectionData.image_caption} width="960" height="520" />
                              </figure>
                              <div className ="" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                           </div>
                        </div>
                     </div>
                     <Sidebar data={latData} />
                  </div>
               </div>
            </section>
         </main>
      <Footer/>
      </div>
   <a href="javascript:void(0)" id="back-to-top">
      <i className="fas fa-angle-double-up"></i>
   </a>
</div>        
        </>
    )
}

export default Detail