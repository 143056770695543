import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () =>{
    return (
        <>
        <footer className="footer footer-style-4">
            <div className="container">
                <div className="footer-widget-style-2 d-flex align-items-center justify-content-center text-center flex-column">
                    <div className="logo wow fadeInUp animated abcWOW1" data-wow-delay="200ms" data-wow-duration="800ms">
                        <NavLink to ="/">
                            <img src="assets/Images/logo-ianslive.png" alt="logo-classNameic" width="170" height="50" />
                        </NavLink>
                    </div>
                    <ul className="footer-social wow fadeInUp animated abcWOW2" data-wow-delay="400ms" data-wow-duration="800ms">
                        <li className="social-item">
                            <a href="https://www.facebook.com/" className="social-link fb" target="_blank">
                            <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://twitter.com/" className="social-link tw" target="_blank">
                            <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://vimeo.com/" className="social-link vm" target="_blank">
                            <i className="fab fa-vimeo-v"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://www.pinterest.com/" className="social-link pn" target="_blank">
                            <i className="fab fa-pinterest-p"></i>
                            </a>
                        </li>
                        <li className="social-item">
                            <a href="https://www.whatsapp.com/" className="social-link wh" target="_blank">
                            <i className="fab fa-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                    <p className="copyright-text mb-0 wow fadeInUp animated abcWOW" data-wow-delay="600ms" data-wow-duration="800ms">
                        <span className="currentYear">2022</span> © all right reserved by
                        <a href="https://ians.in/" rel="nofollow" target="_blank">IANS</a>
                    </p>
                </div>
            </div>
        </footer>        
        </>
    )
}
export default Footer