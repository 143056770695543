import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import Top from './Top'
import Latest from './Latest'
import National from './National';
import International from './International';
import Health from './Health';
import Science from './Science';
import Sports from './Sports';
import {Helmet} from "react-helmet";

const Home =()=>{
    const [topData, setTopData] = useState({})
    const [scDatt, setScienData] = useState({})
    const [sptDatt, setSportsData] = useState({})
    const [helDatat, setHelllData] = useState({})
    const [intDatta, setIntData] = useState({})   
    const [latData, setLatestData] = useState({}) 
    const [bussData, setBussData] = useState({})
    const [enterData, setEntertainmentData] = useState({})  
    const [natList, setNationalListData] = useState({})
    const topSData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=1&top_news=true`
        )
        .then((res) => {
        const topStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setTopData(topStories);
        });
    }

    const LatestData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2`
        )
        .then((res) => {
        const latestStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setLatestData(latestStories);
        });
    }

    const BusinData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2`
        )
        .then((res) => {
        const busStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setBussData(busStories);
        });
    }    
    const NationalData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=national`
        )
        .then((res) => {
        const nationalStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setNationalListData(nationalStories);
        });
    }
    const EntertainmentData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=entertainment`
        )
        .then((res) => {
        const entertainmentStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setEntertainmentData(entertainmentStories);
        });
    }  
     const ScienceData =()=>{
        const scienceApi = "https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=science-tech";
        axios
        .get(
        scienceApi
        )
        .then((res) => {
        const sciStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setScienData(sciStories);
        });
    }  
    const SportsData =()=>{
        const spotApi = "https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=sports";
        axios
        .get(
            spotApi
        )
        .then((res) => {
        const sportssStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setSportsData(sportssStories);
        });
    }      
    const IntData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=international`
        )
        .then((res) => {
        const intStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 7).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setIntData(intStories);
        });
    } 
    const HealthhData =()=>{
        const healtApi = `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=health-medicine`
        axios
        .get(
            healtApi
        )
        .then((res) => {
        const heallStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 7).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setHelllData(heallStories);
        });
    }        
    useEffect(() => {
        topSData();
        LatestData();
        NationalData();
        EntertainmentData();
        IntData();
        BusinData();
        ScienceData();
        SportsData();
        HealthhData();
      }, []);
       
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div id="wrapper" className="wrapper">
            <div id="main_content" className="footer-fixed">
                <Header />
                <main>
                    <Top  top={topData}/>
                    <Latest lat={latData}/>
                    <National listData ={natList} enter={enterData} data={bussData}/>
                    <section className="section-padding rt-main-post-section bg--gray">
                        <div className="container">
                            <div className="row gutter-30">
                                <International  data={intDatta}/>
                                <Health hData={helDatat}/>
                            </div>
                        </div>
                    </section>
                    <section className="section-padding">
                        <div className="container">
                            <div className="row gutter-24 sticky-coloum-wrap">
                                <Science data={scDatt}/>                        
                                <Sports datap={sptDatt}/>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        </div>        
        </>
    )
}

export default Home