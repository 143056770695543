import React,{useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import {NavLink} from 'react-router-dom';
import {useParams} from 'react-router-dom';

const Category = ()=>{
const [categoryName, setCategoryName] = useState('')
const [cateData, setCateData] = useState({})
const [latData, setLatestData] = useState({}) 
const {categoryId, subCat, offset} = useParams();
const [currentPage, setCurrentPage] = useState(1);
const [pageList, setPageList] = useState([1,2,3]);
const [lastPage, setLastPage] = useState(1);
const [lt, setLt] = useState(5);
const [requestFlag, setRequestFlag] = useState(true);
const updateCatName = (name)=>{
    if(name){
        if(name=='bollywood'){
            setCategoryName('बॉलीवुड');
        }else if(name == 'hollywood'){
            setCategoryName('हॉलीवुड');
        }else if(name == 'fashion'){
            setCategoryName('फैशन'); 
        }else if(name == 'beauty'){
            setCategoryName('ब्यूटी '); 
        }else if(name == 'business'){
            setCategoryName('व्यापार ');
        }else if(name == 'tv'){
            setCategoryName('टेलीविजन');        
        }else if(name == 'ott'){
            setCategoryName('ओटीटी');  
        }else if(name == 'science-tech'){
            setCategoryName('विज्ञान और प्रौद्योगिकी');        
        }else if(name == 'entertainment'){
            setCategoryName('मनोरंजन ');  
        }else if(name == 'national'){
            setCategoryName('राष्ट्रीय');  
        }else if(name == 'sports'){
            setCategoryName('खेल');        
        }else if(name == 'international'){
            setCategoryName('अंतरराष्ट्रीय');             
        }else{
            setCategoryName('');                       
        }
    }
}
const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 8).map((item) => {
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/'+item.slug,
            image_caption: item.image_caption,
        };
        });
    setLatestData(latestStories);
    });
}
//alert(categoryId);
const fetchData = async (page) => {
const getApii = `https://cms.iansnews.in/api/news/portals/?language=hindi&website=2&tags=${categoryId}&page=${page}`;
   axios.get(getApii)
     .then(res => {

if(lastPage == 1){
setLastPage(res.data.totNews);

}
const getCatData =
res.data.results &&
res.data.results.length > 0 &&
res.data.results.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.thumbnail,
    title: item.title,
    slug:'/'+item.slug,
    image_caption: item.image_caption,
};
});
        setCateData(getCatData);
        setLt(res.data.numpages);
        setRequestFlag(false);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })

}
useEffect(() => {
  fetchData(currentPage);
  LatestData();
  updateCatName(categoryId);

//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId])


const handleLastPage = () => {
//if(requestFlag){
  setCurrentPage(lt);
  let lastArrtty = [lt-3, lt-2, lt-1]
  setPageList(lastArrtty);
//  }
}
const handleStartPage = () => {
// if(requestFlag){
  setCurrentPage(1);
  let lastArrtty = [1, 2, 3]
  setPageList(lastArrtty);
//  }
}


    return(
        <>
        <div id="wrapper" className="wrapper">
            <div id="main_content" className="footer-fixed">
                <Header />
                <main>
                    <section className="rt-sidebar-section-layout-1">
                        <div className="container">
                            <div className="col-12">
                                <h2 className="rt-section-heading">
                                    <span className="rt-section-text">{categoryName}</span>
                                    <span className="rt-section-dot"></span>
                                    <span className="rt-section-line"></span>
                                </h2>
                            </div>
                            <div className="row gutter-40 sticky-coloum-wrap">
                                <div className="col-xl-9 sticky-coloum-item">
                                    <div className="rt-left-sidebar-sapcer-5">
                                        <div className="row gutter-24">
                                            {cateData && cateData.length > 0 && cateData.slice(0, 9).map((ct, c)=>{
                                                return(
                                                    <div className="col-md-4 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="800ms" key={c}>
                                                    <div className="rt-post-grid post-grid-style-2 grid-meta ">
                                                        <div className="post-img">
                                                            <NavLink to={ct.slug}>
                                                                <img src={ct.image} alt={ct.image_caption} width="551" height="431" />
                                                            </NavLink>
                                                        </div>
                                                        <div className="post-content">
                                                            <h3 className="post-title bold-underline">
                                                                <NavLink to={ct.slug}>
                                                                {ct.title}
                                                                </NavLink>
                                                            </h3>
                                                            <p>{ct.content}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <nav className="rt-pagination-area gap-top-90">
                                            <ul className="pagination rt-pagination justify-content-center">
                                            <li className="page-item prev" onClick={handleStartPage} disabled={currentPage === 1}>
                                                <a className="page-link" href="#"><i className="fas fa-angle-double-left"></i></a>
                                            </li>
                                            {pageList.map((data, i)=>{
                                            return(
                                                <li className={currentPage == data ? 'active page-item' : 'page-item'} key={i} onClick={()=>{
                                            setCurrentPage(data)}}><a href="#" className="page-link">{data}</a></li>
                                                        )
                                                    })}
                                            <li className="page-item next" onClick={handleLastPage} disabled={requestFlag}>
                                                <a className="page-link" href="#"><i className="fas fa-angle-double-right"></i></a>
                                            </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <Sidebar data={latData} />
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
            <a href="javascript:void(0)" id="back-to-top">
            <i className="fas fa-angle-double-up"></i>
            </a>
        </div>
        </>
    )
}
export default Category